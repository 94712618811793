import './App.css';
import Container from 'react-bootstrap/Container';
import RankTable from './components/RankTable';
import Navigation from './components/Navigation';
import InvitationLookup from './components/InvitationLookup';
import { Helmet } from 'react-helmet';

function App() {
  return (
    <div>
      <Helmet>
        <title>Kuplootus</title>
      </Helmet>
      <Navigation /><br />
      <Container>
        <InvitationLookup /><br />
        <RankTable />
      </Container>
    </div>
  )
}

export default App;