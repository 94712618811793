import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import Card from 'react-bootstrap/Card';

const InvitationLookup = () => {
    const [invitationnumber, setInvitationNumber] = useState([])
    const [promos, setPromos] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
    }, [])

    async function callAPI(invnumber) {
        setIsLoading(true)
        const url = `https://api.kuplootus.com/invitation/invitation?number=${invnumber}`
        const response = await axios.get(url)
        setPromos(response.data);
        setIsLoading(false)
    }

    function HandleChange(event) {
        setInvitationNumber(event.target.value);
    }

    return (
        <Card>
            <Card.Header>Invitation Lookup</Card.Header>
            <Card.Body>
            <Card.Title>About</Card.Title>
            <Card.Text>
          This looks up a direct mail invitation code in a Dynamo Database by calling an AWS Lamda function that queries the database.  Try entering ABC123, ABC345, A062100043, HWANG and pressing Search.
        </Card.Text>
            <Form>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Invitation Number</Form.Label>
                    <Form.Control type="text" placeholder="Enter Invitation Number" value={invitationnumber} onChange={HandleChange} />
                </Form.Group>
            </Form>
            <Button variant="primary" onClick={() => callAPI(invitationnumber)} disabled={isLoading}>
                {isLoading ? (
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                ) : (
                    ''
                )}
                Search
            </Button><br /><br />
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Status</th>
                        <th>Message</th>
                        <th>Promo ID</th>
                        <th>Error</th>
                    </tr>
                </thead>
                <tbody>
                    <tr key={promos.promoid}>
                        <td>{promos.status}</td>
                        <td>{promos.message}</td>
                        <td>{promos.promoid}</td>
                        <td>{promos.error}</td>
                    </tr>
                </tbody>
            </Table>

            </Card.Body>

        </Card>
    )
}

export default InvitationLookup