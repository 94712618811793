import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner';
import Card from 'react-bootstrap/Card';

const RankTable = () => {
  const url = 'https://7g5xu2scr6l56c67and54jazay0ikqxq.lambda-url.us-east-2.on.aws/'
  const [ranks, setRanks] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
  }, [])

  async function callAPI() {
    setIsLoading(true)
    setRanks([]);
    const response = await axios.get(url)
    setRanks(response.data);
    setIsLoading(false)
  }

  return (
    <Card style={{ maxHeight: "600px", overflowY: "auto" }}>
      <Card.Header>Web Scraping API</Card.Header>
      <Card.Body>
        <Card.Title>About</Card.Title>
        <Card.Text>
          This calls an AWS Lamda function that scrapes a <a href="https://www.lavuelta.es/en/rankings/stage-4" target="_blank" rel="noreferrer">website</a> using Cheerio and returns an array of JSON objects.  Press Scrape to scrape the data from the website and populate the table.
        </Card.Text>
        <Button variant="primary" onClick={() => callAPI()} disabled={isLoading}>
          {isLoading ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            ''
          )}
          Scrape
        </Button><br /><br />
        <Table striped bordered hover>
          <thead style={{ position: "sticky", top: "0" }}>
            <tr>
              <th>Name</th>
              <th>Number</th>
              <th>Time</th>
              <th>Team</th>
            </tr>
          </thead>
          <tbody>
            {ranks.map((rank) => (
              <tr key={rank.id}>
                <td>{rank.riderName}</td>
                <td>{rank.riderNumber}</td>
                <td>{rank.riderTime}</td>
                <td>{rank.teamName}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  )
}

export default RankTable